// @ts-nocheck

window.XPEDEO_SETTINGS = {
  content: {
    remoteUrl: '',
    useStaticPageFiles: false
  },
  theme: 't-clear',
  showLocationSettingsWarning: true
}
